import Link from "next/link";
import Image from "next/image";
import { Highlight } from "react-instantsearch";
const SearchProduct = ({ product }) => {
  return (
    <Link
      href={`/products/${product?.slug}`}
      className="flex items-center justify-start w-full h-auto group"
    >
      <div className="relative flex flex-shrink-0 w-24 h-24 overflow-hidden bg-gray-200 rounded-md cursor-pointer mr-4">
        <Image
          src={
            product?.images[0]?.url ?? "/assets/placeholder/search-product.svg"
          }
          fill
          loading="eager"
          alt={product.name || "Product Image"}
          className="object-contain bg-gray-200"
          sizes="(max-width: 768px) 500px, (max-width: 1200px) 600px, 500px"
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden">
        <Highlight
          attribute="name"
          className="mb-2 text-sm text-heading"
          hit={product}
        />
        <div className="text-sm font-semibold text-heading">
          from {product?.lowestPrice?.toLocaleString()}
        </div>
      </div>
    </Link>
  );
};

export default SearchProduct;
