import SearchIcon from "../icons/search-icon";
import React, { useState, useEffect, useRef } from "react";
import { useUI } from "@/contexts/ui.context";
import { IoCloseOutline } from "react-icons/io5";
import { useSearchBox } from "react-instantsearch";
import { event } from "@/lib/ga";
export default function SearchBox(props) {
  const { closeSearch } = useUI();
  const { query, refine } = useSearchBox(props);

  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setInputValue(newQuery);
    if (newQuery.length > 4) {
      const payload = {
        search_term: newQuery,
      };
      event("search", payload);
    }
    refine(newQuery);
  }
  return (
    <>
      <div className="relative pr-12 md:pr-14 bg-white overflow-hidden rounded-md w-full">
        <label htmlFor="search" className="flex items-center py-0.5">
          <span className="flex items-center justify-center flex-shrink-0 w-12 h-full cursor-pointer md:w-14 focus:outline-none">
            <SearchIcon color="text-heading" className="w-4 h-4" />
          </span>
          <input
            id="search"
            className="w-full h-12 text-sm placeholder-gray-400 outline-none text-heading lg:h-14 lg:text-base"
            aria-label="Search"
            autoComplete="off"
            name="search"
            type="text"
            value={inputValue}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
          />
        </label>
        <button
          type="button"
          className="absolute top-0 flex items-center justify-center w-12 h-full text-2xl text-gray-400 transition duration-200 ease-in-out outline-none md:text-3xl right-0 md:w-14 hover:text-heading focus:outline-none"
          onClick={closeSearch}
        >
          <IoCloseOutline className="w-6 h-6" />
        </button>
      </div>
    </>
  );
}
