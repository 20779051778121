import Copyright from "./copyright";
import Link from "next/link";
import Image from "next/image";
import CartIcon from "@/components/icons/cart-icon";
import { FaInstagram, FaFacebook, FaRegEnvelope } from "react-icons/fa";
import { footerData } from "./data";
import ContactForm from "./contact";
import UserIcon from "@/components/icons/user-icon";
import { useUI } from "@/contexts/ui.context";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

const iconClass = "text-white lg:text-base text-lg";
const SocialData = [
  {
    label: "Instagram",
    link: "https://www.instagram.com/hypefly.in/",
    icon: <FaInstagram className={iconClass} />,
  },
  {
    label: "Facebook",
    link: "https://www.facebook.com/hypefly.in/",
    icon: <FaFacebook className={iconClass} />,
  },
  {
    label: "Email",
    link: "mailto:contact@hypefly.co.in",
    icon: <FaRegEnvelope className={iconClass} />,
  },
];
const Footer = () => {
  const { data: session } = useSession();
  const router = useRouter();
  return (
    <footer className="sticky md:bottom-0 -bottom-[50px] left-0 w-full z-[0]">
      <div className="bg-[#1A202C] py-12 mt-16 md:mt-12 px-4 md:px-8 lg:px-[5vw]">
        <div className="w-full grid lg:grid-cols-6 grid-cols-2 auto-rows-auto gap-8">
          <LogoContainer />
          {footerData?.map((row, key) => (
            <div
              className="flex flex-col lg:items-start items-center gap-2"
              key={"footerCol" + key}
            >
              <ColumnHeader title={row.widgetTitle} />
              {row?.lists.map((list, key) => (
                <Link
                  href={list.path}
                  className="text-white/70 hover:text-white transition-all duration-300 ease hover:underline w-fit"
                  key={row + list.title + key}
                  aria-label={list.title}
                >
                  {list.title}
                </Link>
              ))}
            </div>
          ))}
          <Misc session={session} router={router} />
        </div>
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer;

const LogoContainer = () => {
  return (
    <div className="flex flex-col lg:items-start items-center justify-between gap-2 col-span-2 w-full md:mt-0 mt-20">
      <Link
        href="/"
        className="lg:w-40 lg:h-20 md:h-16 h-12 w-40 relative mb-4 md:mb-0"
      >
        <Image
          src={"/hypefly-logo-white.png"}
          alt={"Hypefly"}
          fill
          sizes="(max-width: 768px) 160px, (max-width: 1200px) 224px, 224px"
          className="object-contain lg:object-left object-center"
          quality={10}
        />
      </Link>
      <div className="grid grid-cols-3 auto-rows-auto gap-6 w-fit mt-3 mb-6">
        {SocialData?.map((row, key) => (
          <SocialButton link={row.link} key={row.label + key} title={row.label}>
            {row.icon}
          </SocialButton>
        ))}
      </div>
      <div className="flex flex-col w-full lg:items-start items-center justify-start gap-1">
        <ColumnHeader title={"BUSINESS INQUIRIES"} />
        <a
          target="_blank"
          className="text-white/70 hover:text-white text-base hover:underline transition-all duration-300 ease w-fit"
          href="mailto:contact@hypefly.co.in"
        >
          contact@hypefly.co.in
        </a>
      </div>
      <div className="flex flex-col w-full lg:items-start items-center justify-start gap-1 mt-4">
        <ColumnHeader title={"REGISTERED ADDRESS"} />
        <div className="text-white/70 hover:text-white text-base hover:underline transition-all duration-300 ease w-fit lg:text-left text-center lg:max-w-[350px] md:max-w-[400px]">
          HypeFly India Private <br />
          Limited Flat 3B, Mukunt Building, 20 SV Road,
          <br />
          Bandra West, Mumbai 400050
        </div>
      </div>
    </div>
  );
};
const SocialButton = ({ link, children, title }) => {
  return (
    <a
      className="cursor-pointer bg-white/[0.06] rounded-full w-10 h-10 flex items-center justify-center hover:bg-black transition-all duration-300 ease"
      href={link ?? "#"}
      aria-label={title}
    >
      {children}
    </a>
  );
};
const ColumnHeader = ({ title }) => {
  return (
    <p className="text-white md:text-lg text-sm font-avenir font-semibold uppercase lg:text-left text-center">
      {title}
    </p>
  );
};
const Misc = ({ session, router }) => {
  const { openCart, openModal, setModalView } = useUI();
  function handleCartOpen() {
    return openCart();
  }
  function handleLogin() {
    if (session) {
      router.push("/my-account");
    } else {
      setModalView("LOGIN_VIEW");
      return openModal();
    }
  }
  const iconStyle =
    "cursor-pointer bg-white/[0.06] rounded-full w-10 h-10 flex items-center justify-center hover:bg-black transition-all duration-300 ease";

  return (
    <div className="flex flex-col lg:items-start items-center justify-start gap-6 w-full col-span-2">
      <div className="flex flex-col gap-2 items-start justify-start">
        <ColumnHeader title={"Contact Us"} />
        <ContactForm />
      </div>
      <div className="flex flex-col gap-2">
        <ColumnHeader title={"ACCOUNT"} />
        <div className="w-fit grid grid-cols-2 auto-rows-auto md:gap-6 gap-8">
          <div className={iconStyle} onClick={handleLogin}>
            <UserIcon className={iconClass} width={16} height={16} />
          </div>
          <button
            className={iconStyle}
            onClick={handleCartOpen}
            aria-label="cart-button"
          >
            <CartIcon className={iconClass} width={16} height={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
